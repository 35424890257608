import React, { Component } from 'react';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const customTextField = {
    // Root class for the input field
    "& .MuiOutlinedInput-root": {
      color: "#006FCA",
      backgroundColor: '#fff',
      // Class for the border around the input field
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#006FCA",
        borderWidth: "1px",
      },
      "& MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: '#d32f2f !important'
      }
    },
    // Class for the label of the input field
    "& .MuiInputLabel-outlined": {
      color: "#006FCA",
    },
}

export default class RestablecerPassword extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            error: {
                email: { error: false, text: '', required: true },
            },
            blockButton: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        if (error[name].required && (value === '' || value === 0)) {
            error[name].error = true;
            error[name].text = 'este campo es obligatirio';
            this.setState({ blockButton: true });
        } else {
            error[name].error = false;
            error[name].text = '';
            this.setState({ blockButton: false });
        }
        let validEmail = /@/.test(value);
        if (!validEmail) {
            error[name].error = true;
            error[name].text = 'Email incorrecto';
            this.setState({ blockButton: true });
        }
        this.setState({ error })

    }

    sendEmail() {
        if (this.validateFields()) {
            firebase.auth().sendPasswordResetEmail(this.state.email)
                .then(() => {
                    toast.info(
                        'Solitud de cambio de contraseña correcto, revise su correo para continuar con el proceso',
                        {
                            onClose: () => { this.props.navigate('/'); }
                        }
                    );

                })
                .catch((err) => {
                    toast.error('Error al solicitar cambio de clave, revisar conexión a internet');
                })
        }
    }

    validateFields() {
        let validation = true;
        let error = { ...this.state.error };
        let validEmail = /@/.test(this.state.email);
        if (!validEmail) {
            error.email.error = true;
            error.email.text = 'Email incorrecto';
            validation = false;
        }
        if (!validation) {
            this.setState({ error, blockButton: true })
        } else {
            this.setState({ error, blockButton: false })
        }
        return validation;
    }

    render() {
        return (
            <div className='container-div-login'>
                <div className="login-div">
                    <div className="form-login">
                        <div className="div-element">
                            <img src="/assets/logo-header.png" 
                                alt="Da Comprando"
                                width={160} 
                                height={90}
                            />
                            <h2 className="title-login" style={{fontSize: 24}}>Solicitar cambio de contraseña</h2>
                        </div>
                        <div className="div-element">
                            <TextField
                                name="email"
                                label="Correo electrónico"
                                value={this.state.email}
                                error={this.state.error.email.error}
                                helperText={this.state.error.email.error ? this.state.error.email.text : ''}
                                fullWidth
                                margin="normal"
                                required={this.state.error.email.required}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                                sx={customTextField}
                            />
                        </div>
                        <div className="div-element">
                            <Button
                                style={{ width: "100%", textTransform: 'none', height: 46 }}
                                variant="contained"
                                onClick={this.sendEmail.bind(this)}
                                disabled={this.state.blockButton}
                                aria-label='Solicitar'
                            >Solicitar</Button>
                        </div>
                        <div className="div-element" style={{ textAlign: 'right' }}>
                            <Link className="text-login text-color-blue" to='/'>Regresar</Link>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
