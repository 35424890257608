import React, { Component } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../App.css";

import {
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

const customTextField = {
  // Root class for the input field
  "& .MuiOutlinedInput-root": {
    color: "#006FCA",
    backgroundColor: '#fff',
    // Class for the border around the input field
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#006FCA",
      borderWidth: "1px",
    },
    "& MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: '#d32f2f !important'
    }
  },
  // Class for the label of the input field
  "& .MuiInputLabel-outlined": {
    color: "#006FCA",
  },
}

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      showPassword: "",
      errorEmail: false,
      errorPass: false,
      message: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.login = this.login.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  login() {
    let validation = this.validateFields();
    if (validation) {
      let auth = getAuth();
      signInWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then((user) => {
          if (user.user.emailVerified) {
            this.props.navigate("/");
          } else {
            toast.warning(
              "Debe verificar su cuenta para poder ingresar, verifique que el email que le enviamos no este en spam y haga click en el link de verificación"
            );
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            toast.error("Contraseña incorrecta");
          } else {
            // toast.error('El usuario no existe por favor registrese, si ya se registro verifique su cuenta para poder iniciar sesion. Si tuvo problemas con la verificacion comuniquese con nosotros');
            toast.error("El usuario no existe por favor registrese");
          }
        });
    }
  }

  validateFields() {
    let validation = true;

    if (this.state.email == "") {
      toast.error("El campo email es obligatorio");
      validation = false;
    } else {
      let validEmail = /@/.test(this.state.email);
      if (!validEmail) {
        toast.error("Email incorrecta");
        validation = false;
      }
    }
    if (this.state.password == "") {
      toast.error("El campo contraseña es obligatorio");
      validation = false;
    } else {
      if (this.state.password.length < 6) {
        toast.error("La contraseña debe tener mas de 6 caracteres");
        validation = false;
      }
    }
    return validation;
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="container-div-login">
        <div className="login-div">
          <div className="form-login">
            <div style={{textAlign: 'center'}}>
              <img
                src="/assets/logo-header.png"
                alt="Da Comprando"
                height={78}
              />
              <h2 className="title-login">Bienvenido</h2>
              <div className="text-login">Iniciar sesión</div>
            </div>
            <div style={{marginBottom: 16}}>
              <div>
                <FormControl variant="outlined" fullWidth margin="normal" sx={customTextField}>
                  <InputLabel htmlFor="email">Correo electrónico</InputLabel>
                  <OutlinedInput
                    id="email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    label="Correo electrónico"
                    error={this.state.errorEmail}
                  />
                  {this.state.errorEmail && (
                    <FormHelperText style={{ color: "red" }}>
                      Nunca compartiremos tu correo electrónico.
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div>
                <FormControl variant="outlined" fullWidth margin="normal" sx={customTextField}>
                  <InputLabel htmlFor="password">Contraseña</InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña"
                  />
                </FormControl>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <Button
                style={{ width: "100%", textTransform: 'none', height: 46 }}
                variant="contained"
                onClick={this.login.bind()}
                aria-label="Ingresar"
              >
                Ingresar
              </Button>
            </div>
            <div className="div-element">
              <Link className="text-login text-color-blue" to="/restablecerpassword">
                Olvidó su contraseña?
              </Link>
            </div>
            <div className="div-element text-login">
              Si no tiene cuenta,{" "}
              <Link className="text-login text-color-blue" to="/register">
                Regístrese aquí
              </Link>
            </div>
            <div style={{ textAlign: "right" }}>
              <Link className="text-login text-color-blue" to="http://dacomprando.com.ec/">
                Regresar
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
