import React, { Component } from 'react';
import { Button, TextField, InputAdornment, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const customTextField = {
    // Root class for the input field
    "& .MuiOutlinedInput-root": {
      color: "#006FCA",
      backgroundColor: '#fff',
      // Class for the border around the input field
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#006FCA",
        borderWidth: "1px",
      },
      "& MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: '#d32f2f !important'
      }
    },
    // Class for the label of the input field
    "& .MuiInputLabel-outlined": {
      color: "#006FCA",
    },
}

export default class ChangePassword extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            confirm_password: '',
            showPassword: {
                pass: false,
                confirm: false
            },
            error: {
                password: { error: false, text: '', required: true },
                confirm_password: { error: false, text: '', required: true },
            },
            blockButton: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        } else {
            if (name === 'password') {
                if (value.length < 6) {
                    error = this.setErrorField(error, name, true, 'La contraseña debe tener mas de 6 caracteres');
                }
            }

            if (name === 'confirm_password') {
                if (value !== this.state.password) {
                    error = this.setErrorField(error, name, true, 'La contraseña no coiciden');
                }
            }
        }
        this.setState({ error })
    }

    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        this.setState({ blockButton: status });
        return error;
    }

    handleClickShowPassword = (type) => () => {
        let showPassword = { ...this.state.showPassword }
        if (type === 'P') {
            showPassword.pass = !showPassword.pass;
        } else {
            showPassword.confirm = !showPassword.confirm;
        }
        this.setState({ showPassword });
    };

    handleMouseDownPassword(event) {
        event.preventDefault();
    };

    sendEmail() {
        if (this.validateFields()) {
            let parametros = new URLSearchParams(this.props.data.location.search)
            firebase.auth().confirmPasswordReset(parametros.get('oobCode'), this.state.password)
                .then(() => {
                    toast.info('Contraseña cambiada correctamente')
                    this.props.data.navigate('/')
                })
                .catch(error => {

                })
        }
    }

    validateFields() {
        let validation = true;
        let error = { ...this.state.error };

        if (this.state.password.length < 6) {
            error.password.error = true;
            error.password.text = 'La contraseña debe tener mas de 6 caracteres';
            validation = false;
        }

        if (this.state.confirm_password !== this.state.password) {
            error.confirm_password.error = true;
            error.confirm_password.text = 'La contraseña no coiciden';
            validation = false;
        }

        if (!validation) {
            this.setState({ error, blockButton: true })
        } else {
            this.setState({ error, blockButton: false })
        }
        return validation;
    }

    render() {
        return (
            <div className='container-div-login'>
                <div className="login-div">
                    <div className="form-login">
                        <div className="div-element">
                            <h2 className="title-login" style={{fontSize: 24}}>Cambiar contraseña</h2>
                        </div>
                        <div className="div-element">
                            <TextField
                                type={this.state.showPassword.pass ? 'text' : 'password'}
                                name='password'
                                label='Nueva contraseña'
                                value={this.state.password ? this.state.password : ""}
                                error={this.state.error.password.error}
                                helperText={this.state.error.password.error ? this.state.error.password.text : ''}
                                fullWidth
                                margin="normal"
                                required={this.state.error.password.required}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                                sx={customTextField}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword('P')}
                                            onMouseDown={this.handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {this.state.showPassword.pass ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <TextField
                                type={this.state.showPassword.confirm ? 'text' : 'password'}
                                name='confirm_password'
                                label='Confirmar contraseña'
                                value={this.state.confirm_password ? this.state.confirm_password : ""}
                                error={this.state.error.confirm_password.error}
                                helperText={this.state.error.confirm_password.error ? this.state.error.confirm_password.text : ''}
                                fullWidth
                                margin="normal"
                                required={this.state.error.confirm_password.required}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                                sx={customTextField}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword('C')}
                                            onMouseDown={this.handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {this.state.showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="div-element">
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                onClick={this.sendEmail.bind(this)}
                                disabled={this.state.blockButton}
                                aria-label='Cambiar clave'
                            >Cambiar clave</Button>
                        </div>
                        <div className="div-element" style={{ textAlign: 'right' }}>
                            <Link className="text-login text-color-blue" to='/'>Regresar</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
