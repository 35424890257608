import React, { Component } from 'react';
import { Container } from '@mui/material';
import 'firebase/compat/auth';
import Verificacion from './Verificacion';
import ChangePassword from './ChangePassword';

export default class Autenticacion extends React.Component {

    constructor() {
        super();
        this.state = {
            mode: ''
        }
    }
    componentDidMount() {
        let parametros = new URLSearchParams(this.props.location.search)
        this.setState({ mode: parametros.get("mode") })
    }

    render() {
        let showImage = true;
        if (window.screen.width <= 480) {
            showImage = false;
        }
        let heightBlock = window.screen.height - 60 - 144;
        return (
            <div className='container-div'>
                <Container>
                    {this.state.mode == 'verifyEmail' &&
                        <Verificacion data={this.props} />

                    }
                    {this.state.mode == 'resetPassword' &&
                        <ChangePassword data={this.props} />
                    }
                </Container>
            </div>
        )
    }
}