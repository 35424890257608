import React from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { grey } from '@mui/material/colors';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default class Verificacion extends React.Component {

    constructor() {
        super();
        this.sendEmail = this.sendEmail.bind(this);
    }

    sendEmail() {
        let parametros = new URLSearchParams(this.props.data.location.search)
        firebase.auth().applyActionCode(parametros.get('oobCode'))
            .then(() => {
                toast.info('Correo verificado correctamente')
                this.props.data.navigate('/')
            })
            .catch((error) => {
                // error
            });
    }

    render() {
        return (
            <div className='container-div-login'>
                <div className="login-div">
                    <div className="form-login">
                        <div className="div-element">
                            <h2 className="title-login" style={{fontSize: 24}}>Verificar Correo</h2>
                        </div>
                        <div className="div-element">
                            <Button
                                style={{ width: "100%", textTransform: 'none', height: 46 }}
                                variant="contained"
                                onClick={this.sendEmail.bind(this)}
                                aria-label='Solicitar'
                            >Verificar</Button>
                        </div>
                        <div className="div-element" style={{ textAlign: 'right' }}>
                            <Link className="text-login text-color-blue" to='/'>Regresar</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}